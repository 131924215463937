import { ApolloClient, gql, createHttpLink, InMemoryCache, ApolloQueryResult, FetchResult } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AccessToken } from './Authentication';
import { Config } from './Config';
import { TrainingEpisodeDataModel, InteractionDataModel } from './Models';

export default class GraphQLClient {

  async getQuizData(token: AccessToken, identifier: string) : Promise<ApolloQueryResult<any>> {

    const httpLink = createHttpLink({
      uri: `${Config.apiUri}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token.access_token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return client.query({
        query: gql`
          query {
              trainingEpisodeContent(identifier: "${identifier}", languageCode: "en") {
                title
                summary
                isComplete
                questions {
                    number
                    question
                    answers {
                        number
                        answer
                        isCorrect
                        score
                    }
                }
                sections {
                    number
                    title
                    summary
                    questions {
                        number
                        question
                        answers {
                            number
                            answer
                            isCorrect
                            score
                        }
                    }
                }
            }
        }`
    });    
  }

  async postQuizResponse(token: AccessToken, data: TrainingEpisodeDataModel) : Promise<FetchResult<any>> {

    const httpLink = createHttpLink({
      uri: `${Config.apiUri}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token.access_token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return client.mutate({
        mutation: gql`
        mutation CreateTrainingEpisodeData(
          $trainingEpisodeData: TrainingEpisodeDataInput!
        ) {
            createTrainingEpisodeData(trainingEpisodeData: $trainingEpisodeData) {
            trainingEpisode {
                episodeScore
              }
            }
        }
      `,
      variables: {
        trainingEpisodeData: data
      }
       });

    
  }

  async createInteraction(token: AccessToken, interactionData: InteractionDataModel) : Promise<FetchResult<any>> {
debugger;
    const httpLink = createHttpLink({
      uri: `${Config.apiUri}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token.access_token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return client.mutate({
        mutation: gql`
        mutation CreateInteraction(
          $interactionInputData: InteractionInput!
        ) {
          createInteraction(interaction: $interactionInputData) {
            interaction {
              campaignIdentifier
              }
            }
        }
      `,
      variables: {
        interactionInputData: interactionData
      }
       });

    
  }

  async getAnswerData(token: AccessToken, identifier: string) : Promise<ApolloQueryResult<any>> {

    const httpLink = createHttpLink({
      uri: `${Config.apiUri}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token.access_token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return client.query({
        query: gql`
          query {
            trainingEpisodeData(trainingEpisodeIdentifier: "${identifier}") {
              edges {
                node {
                  user
                  episodeScore
                  questionData {
                    questionNumber
                    answerNumber
                    attempts
                  }
                }
              }
            }
        }`
    });    
  }
}

