import axios from 'axios';
import jwt from 'jwt-decode'
import { Config } from './Config';
import { InteractionDataModel, InteractionEventType } from './Models';
import GraphQLClient from './GraphQLClient'

const qs = require('qs');

const gqlClient = new GraphQLClient();

export default class Authentication {

  isLoggedIn(): boolean {
    return localStorage.getItem('token') != null;
  }

  async roles(): Promise<Array<string>> {
    let token = await this.checkToken("");
    let returnValue = new Array<string>();

    if (token != null)
    {
      try
      {
        const user: any = jwt(token.id_token);

        for (let i = 0; i < user.role.length; i++)
        {
          returnValue.push(user.role[i]);
        }        
      }
      catch {}
    }

    return returnValue;
  }

  async checkToken(code: string): Promise<AccessToken> {
    var tokenRaw = localStorage.getItem('token');
    
    var token = null;

    if (tokenRaw != null && tokenRaw !== "") {
      try {
        token = JSON.parse(tokenRaw);
      }
      catch { }
    }

    if ("" !== code) {
      token = await this.getNewToken(code);
      token.expires_ticks = Date.now() + (token.expires_in * 1000);
      localStorage.setItem('token', JSON.stringify(token));

      let interactionData: InteractionDataModel = new InteractionDataModel(InteractionEventType.WebSessionStart, window.location.pathname, "", new Date(), "", "", 0, new Array<string>());

      await gqlClient.createInteraction(token, interactionData);
    }
    else if (null !== token && Date.now() >= token.expires_ticks) {
      let newToken = await this.refreshToken(token);
      token.access_token = newToken.access_token;
      token.expires_ticks = Date.now() + (token.expires_in * 1000);
      localStorage.setItem('token', JSON.stringify(token));
    }

    return token;
  }

  async getNewToken(code: string): Promise<AccessToken> {

    return axios({
      method: 'post',
      url: `${Config.apiUri}/connect/token`,
      data: qs.stringify({
        'client_id': 'hpvillage',
        'client_secret': 'GI4X9391eIcMLAozSUxtPSMR6x5Qhz2cyJH15BLBqRz+NdH+CQWVZACUfn901/6/QMpVsxQakhBDAQrpLRoGQw==',
        'grant_type': 'authorization_code',
        'scope': 'openid profile offline_access roles email',
        'code': `${code}`,
        'redirect_uri': `${Config.appUri}/login`
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then(response => {
      return response.data;
    });
  }

  async refreshToken(token: AccessToken): Promise<AccessToken> {

    return axios({
      method: 'post',
      url: `${Config.apiUri}/connect/token`,
      data: qs.stringify({
        'client_id': 'hpvillage',
        'client_secret': 'GI4X9391eIcMLAozSUxtPSMR6x5Qhz2cyJH15BLBqRz+NdH+CQWVZACUfn901/6/QMpVsxQakhBDAQrpLRoGQw==',
        'grant_type': 'refresh_token',
        'scope': 'openid profile offline_access roles email',
        'refresh_token': token.refresh_token
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then(response => {
      return response.data;
    });
  }
}

export class AccessToken {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_ticks: number;
  expires_in: number;

  constructor(accessToken: string, refreshToken: string, idToken: string, expiresParsed: number, expiresIn: number) {
    this.access_token = accessToken;
    this.refresh_token = refreshToken;
    this.id_token = idToken;
    this.expires_ticks = expiresParsed;
    this.expires_in = expiresIn;
  }
}
