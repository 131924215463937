import { Component } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { InteractionDataModel, InteractionEventType } from '../Models';
import GraphQLClient from '../GraphQLClient'
import Authentication from '../Authentication'

interface IProps {
}

interface IState {
}

interface IRouteParams {
    
}

const gqlClient = new GraphQLClient();
const authentication = new Authentication();

class Logout extends Component<IProps & RouteComponentProps<IRouteParams>, IState> {

    constructor(props: any) {
        super(props);

        localStorage.clear();
        window.location.href = "/";

        /*authentication.checkToken("").then((result) => {
			if (null !== result) {
				let interactionData: InteractionDataModel = new InteractionDataModel(InteractionEventType.AppOpen, window.location.pathname, "", new Date(), "", "", 0, new Array<string>());
        		gqlClient.createInteraction(result, interactionData).then((result) => {
                    localStorage.clear();
                    window.location.href = "/";
                });
			}
		});*/

        
    }
}

export default withRouter(Logout);