export class QuizModel {
    public title?: string;
    public summary?: string;
    public details: DetailsModel;
    public sections: Array<SectionModel>;
    public questions: Array<QuestionModel>;
    public outroContent: Array<OutroContentModel>;
    public isComplete: boolean;
  
    constructor(title: string, summary: string, details: DetailsModel, sections: Array<SectionModel>, questions: Array<QuestionModel>, outroContent: Array<OutroContentModel>, isComplete: boolean) {
      this.title = title;
      this.summary = summary;
      this.details = details;
      this.sections = sections;
      this.questions = questions;
      this.outroContent = outroContent;
      this.isComplete = isComplete;
    }
  }
  
  export class DetailsModel {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    emailConsent: boolean;
    contactConsent: boolean;
  
    constructor(firstName: string, lastName: string, email: string, companyName: string, emailConsent: boolean, contactConsent: boolean) {
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.companyName = companyName;
      this.emailConsent = emailConsent;
      this.contactConsent = contactConsent;
    }
  }
  
  export class SectionModel {
    key: number;
    title: string;
    summary: string;
    questions: Array<QuestionModel>;
  
    constructor(order: number, title: string, summary: string, questions: Array<QuestionModel>) {
      this.key = order;
      this.title = title;
      this.summary = summary;
      this.questions = questions;
    }
  }
  
  export class QuestionModel {
    key: number;
    text: string;
    answers: Array<AnswerModel>;
    score: number;
    userAnswer: string;
    userAnswerIndex: number;
    
    constructor(order: number, text: string, answers: Array<AnswerModel>, score: number, userAnswer: string, userAnswerIndex: number) {
      this.key = order;
      this.text = text;
      this.answers = answers;
      this.score = score;
      this.userAnswer = userAnswer;
      this.userAnswerIndex = userAnswerIndex;
    }
  }
  
  export class AnswerModel {
    key: number;
    text: string;
    isCorrect: boolean;
    score: number;
  
    constructor(order: number, text: string, isCorrect: boolean, userAnswer: string, score: number) {
      this.key = order;
      this.text = text;
      this.isCorrect = isCorrect;
      this.score = score;
    }
  }
  
  export class OutroContentModel {
    title: string;
    summary: string;
    closeButtonText: string;
    
    constructor(title: string, summary: string, closeButtonText: string) {
      this.title = title;
      this.summary = summary;
      this.closeButtonText = closeButtonText;
    }
  }

  export class BirdseyeZoneModel {
    id: number;
    coords: string;
    
    constructor(id: number, coords: string) {
      this.id = id;
      this.coords = coords;
    }
  }

  export class ZoneModel {
    id: string;
    zoneState: Array<ZoneStateModel>;
    
    constructor(id: string, zoneState: Array<ZoneStateModel>) {
      this.id = id;
      this.zoneState = zoneState;
    }
  }

  export class ZoneStateModel {
    image: string;
    start: Date;
    finish: Date;
    finishDisplay: string;
    quizId: string;
    closed: boolean;
    
    constructor(image: string, start: Date, finish: Date, quizId: string) {
      this.image = image;
      this.start = start;
      this.finish = finish;
      this.quizId = quizId;

      this.finishDisplay = `${finish.getDate()}/${finish.getMonth()+1}/${finish.getFullYear()}`;
      this.closed = new Date() > finish;
    }
  }

  export class HintModel {
    coords: string;
    text: string;
    
    constructor(coords: string, text: string) {
      this.coords = coords;
      this.text = text;
    }
  }

  export class TrainingEpisodeDataModel {
    trainingEpisodeIdentifier: string;
    questionData: Array<TrainingEpisodeQuestionDataModel>;
    
    constructor(trainingEpisodeIdentifier: string, questionData: Array<TrainingEpisodeQuestionDataModel>) {
      this.trainingEpisodeIdentifier = trainingEpisodeIdentifier;
      this.questionData = questionData;
    }
  }

  export class TrainingEpisodeQuestionDataModel {
    questionNumber: number;
    answerNumber: number;
    attempts: number;
    
    constructor(questionNumber: number, answerNumber: number, attempts: number) {
      this.questionNumber = questionNumber;
      this.answerNumber = answerNumber;
      this.attempts = attempts;
    }
  }

  export class UserDataModel {
    user: string;
    episodeScore: number;
    questionData: Array<UserQuestionDataModel>;
    winner: boolean;
    questionCount: number;
    correctAnswerCount: number;
    eligible: boolean;
    
    constructor(user: string, episodeScore: number, questionData: Array<UserQuestionDataModel>, winner: boolean, questions: Array<QuestionModel>) {
      this.user = user;
      this.episodeScore = episodeScore;
      this.questionData = questionData;
      this.winner = winner;
      this.correctAnswerCount = 0;
      this.questionCount = questions.length;

      let answerCount = 0;

      questionData.map((userQuestion: UserQuestionDataModel) => {

        let quizQuestion = questions.filter((question: QuestionModel) => { return question.key == userQuestion.questionNumber})[0];
        let quizCorrectAnswer = quizQuestion.answers.filter((question: AnswerModel) => { return question.isCorrect == true})[0];

        if (userQuestion.answerNumber == quizCorrectAnswer.key)
        {
          answerCount++;
        }
      });

      this.correctAnswerCount = answerCount;
      this.eligible = (answerCount == questions.length);
    }
  }

  export class UserQuestionDataModel {
    questionNumber: number;
    answerNumber: number;
    attempts: number;
    
    constructor(questionNumber: number, answerNumber: number, attempts: number) {
      this.questionNumber = questionNumber;
      this.answerNumber = answerNumber;
      this.attempts = attempts;
    }
  }

  export class EmailExportModel {
    email: string;
        
    constructor(email: string) {
      this.email = email;
    }
  }

  export class InteractionDataModel {
    eventType: InteractionEventType;
    eventIdentifier: string;
    eventValue: string;
    campaignIdentifier: string;
    clientIpAddress: string;
    score: number;
    tags: Array<string>;
            
    constructor(eventType: InteractionEventType, eventIdentifier: string, eventValue: string, occurredOn: Date, campaignIdentifier: string, clientIpAddress: string, score: number, tags: Array<string>) {
      this.eventType = eventType;
      this.eventIdentifier = eventIdentifier;
      this.eventValue = eventValue;
      this.campaignIdentifier = campaignIdentifier;
      this.clientIpAddress = clientIpAddress;
      this.score = score;
      this.tags = tags;
    }
  }

  export class SpriteModel {
    image: any;
    x: number;
    y: number;
    w: number;
    h: number;
                
    constructor(imageUri: string, x: number, y: number, w: number, h: number) {
      this.x = x;
      this.y = y;
      this.w = w;
      this.h = h;

      var image = new Image();
        image.src = imageUri;

        this.image = image;
    }
  }

  export enum InteractionEventType {
    WebSessionStart = "WEB_SESSION_START",
    WebSessionEnd = "WEB_SESSION_END",
    WebPageView = "WEB_PAGE_VIEW",
    WebPageVisible = "WEB_PAGE_VISIBLE",
    WebPageScroll = "WEB_PAGE_SCROLL",
    WebClick = "WEB_CLICK",
    WebFormSubmit = "WEB_FORM_SUBMIT",
    WebFormDataInput = "WEB_FORM_DATA_INPUT",
    WebFormValidation = "WEB_FORM_VALIDATION",
    WebDownload = "WEB_DOWNLOAD",
    WebExternalLink = "WEB_EXTERNAL_LINK",
    WebVideoPlay = "WEB_VIDEO_PLAY",
    WebVideoPause = "WEB_VIDEO_PAUSE",
    WebVideoComplete = "WEB_VIDEO_COMPLETE",
    WebSearch = "WEB_SEARCH",
    WebError = "WEB_ERROR",
    WebModalOpen = "WEB_MODAL_OPEN",
    WebModalClose = "WEB_MODEL_CLOSE",
    EmailOpen = "EMAIL_OPEN",
    EmailClick = "EMAIL_CLICK",
    AppOpen = "APP_OPEN",
    AppClose = "APP_CLOSE",
    AppVideoPlay = "APP_VIDEO_PLAY",
    AppArticleView = "APP_ARTICLE_VIEW",
    AppShare = "APP_SHARE",
    AppDownload = "APP_DOWNLOAD",
    AppInvite = "APP_INVITE",
    AppVote = "APP_VOTE",
    AppArticleRequest = "APP_ARTICLE_REQUEST",
    AppFormSubmit = "APP_FORM_SUBMIT",
    AppSearch = "APP_SEARCH",
    AppExternalLink = "APP_EXTERNAL_LINK",
    UserSignUp = "USER_SIGN_UP",
    TrainingEpisodeView = "TRAINING_EPISODE_VIEW",
    TrainingEpisodeCompleted = "TRAINING_EPISODE_COMPLETED",
    TrainingPrizeWin = "TRAINING_PRIZE_WIN",
    TrainingSeasonCompleted = "TRAINING_SEASON_COMPLETED",
    TrainingCategoryCompleted = "TRAINING_CATEGORY_COMPLETED",
    PlatformAuthRequest = "PLATFORM_AUTH_REQUEST",
    CampaignEnrol = "CAMPAIGN_ENROL",
    Search = "SEARCH",
    AssetDownload = "ASSET_DOWNLOAD",
    AssetView = "ASSET_VIEW",
    AssetShare = "ASSET_SHARE",
    FeedItemView = "FEED_ITEM_VIEW",
    DealRegistration = "DEAL_REGISTRATION",
    OpportunityRegistration = "OPPORTUNITY_REGISTRATION"
  }