import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TouristInformation from './TouristInformation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSignOutAlt, faHome, faPlus, faMinus, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import hpiLogo from '../images/hpi-logo.svg'
import impsLogo from '../images/imps-hero-logo.png'
import Authentication from '../Authentication'
import Moment from 'react-moment';
import 'moment-timezone';

interface IProps {
    anchorDate: Date;
    incrementDate: any;
    decrementDate: any;
    incrementDevModeClicks: any;
    devMode: boolean;
}

interface IRouteParams {
}

interface IState {
    displayTouristInfo: boolean,
    roles: Array<string>,
    isLoggedIn: boolean
}

const authentication = new Authentication();

class Header extends Component<IProps & RouteComponentProps<IRouteParams>, IState> {

    private devModeRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.devModeRef = React.createRef();

        this.state = {
            displayTouristInfo: (this.props.match.path != "/" && localStorage.getItem("displayTouristInfo") == null),
            roles: new Array<string>(),
            isLoggedIn: (localStorage.getItem('token') != null)
        };
    }

    async componentDidMount() {

        let roles = await authentication.roles();

        this.setState(state => {

            const displayTouristInfo = state.displayTouristInfo;

            return {
                displayTouristInfo: displayTouristInfo,
                roles: roles
            }

        });
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.devMode !== this.props.devMode) {
            this.updateAndNotify();
        }
    }

    updateAndNotify = () => {

        if (this.props.devMode)
        {
            if (null !== this.devModeRef.current) {
                this.devModeRef.current.classList.toggle('devModeShow');
            }
        }
        
    }

    goHome = () => {
        this.props.history.push("/birdseye");
    }

    goAdmin = () => {
        this.props.history.push("/admin");
    }

    toggleTouristInfo = () => {

        this.setState(state => {

            const displayTouristInfo = !state.displayTouristInfo;
            localStorage.setItem("displayTouristInfo", displayTouristInfo.toString());

            return {
                displayTouristInfo
            }

        });

    };

    logOut = () => {
        this.props.history.push("/logout");
    };

    render() {

        let admin = <></>;

        if (this.state.roles.filter(role => role == "training_admin").length == 1)
        {
            admin = <>
            <span className={"headerLink"} onClick={this.goAdmin.bind(this)}>
                <FontAwesomeIcon icon={faUsersCog} /><span className="mobile-hide">&nbsp;Admin</span>
            </span>
            </>;
        }

        return (<>
            <div className="left-half">
                <img src={hpiLogo} onClick={this.props.incrementDevModeClicks} />
                <span className={`headerLink ${(this.props.match.path == "/birdseye" || this.props.match.path == "/")?'loggedOut':''}`} onClick={this.goHome.bind(this)}>
                    <FontAwesomeIcon icon={faHome} /><span className="mobile-hide">&nbsp;Home</span>
                </span>
                <div className="devMode" ref={this.devModeRef} >
                    <span><FontAwesomeIcon icon={faMinus} onClick={this.props.decrementDate} /><Moment date={this.props.anchorDate} format="DD/MM/YYYY" /><FontAwesomeIcon icon={faPlus} onClick={this.props.incrementDate} /></span>
                </div>
            </div>
            <div className="right-half">
                
                {admin}

                <span className={`headerLink ${(this.props.match.path == "/")?'loggedOut':''}`} onClick={this.toggleTouristInfo.bind(this)}>
                    <FontAwesomeIcon icon={faInfoCircle} /><span className="mobile-hide">&nbsp;Help Hub</span>
                </span>

                <span className={`headerLink ${(!this.state.isLoggedIn)?'loggedOut':''}`} onClick={this.logOut.bind(this)}>
                    <FontAwesomeIcon icon={faSignOutAlt} /><span className="mobile-hide">&nbsp;Log Out</span>
                </span>
                
                <img src={impsLogo} />
            </div>
            <TouristInformation show={this.state.displayTouristInfo} onContinue={this.toggleTouristInfo} />
        </>);
    }
}

export default withRouter(Header);