import React from 'react';
import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

import Authentication from '../Authentication'
import GraphQLClient from '../GraphQLClient'
import { QuizModel, DetailsModel, SectionModel, QuestionModel, AnswerModel, OutroContentModel, TrainingEpisodeDataModel, TrainingEpisodeQuestionDataModel } from '../Models';
import Question from './Question';
import Progress from './Progress';

interface IProps {
    quizIdentifier: string,
    show: boolean,
    onComplete: any
}

interface IState {
    quiz: QuizModel;
    currentSection: number;
    currentQuestion: number;
    currentOutro: number;
    started: boolean;
    completed: boolean;
}

interface IRouteParams {
}

const authentication = new Authentication();
const gqlClient = new GraphQLClient();
const outroContent = new Array<OutroContentModel>(
    new OutroContentModel("Thank you for taking part in the quiz!", "Don't forget to look out for an email in the next few days, if you chose all the correct answers you might win a prize!", "Close")
);

class Quiz extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            quiz: new QuizModel("", "", new DetailsModel("", "", "", "", false, false),
                new Array<SectionModel>(), new Array<QuestionModel>(), new Array<OutroContentModel>(), false),
            currentSection: -1,
            currentQuestion: -1,
            currentOutro: -1,
            started: false,
            completed: false
        };
    }

    async componentDidMount() {
        
        var token = await authentication.checkToken("");
        
        this.state = {
            quiz: new QuizModel("", "", new DetailsModel("", "", "", "", false, false), new Array<SectionModel>(), new Array<QuestionModel>(), new Array<OutroContentModel>(), false),
            currentSection: -1,
            currentQuestion: -1,
            currentOutro: -1,
            started: false,
            completed: false
        }

        
        await gqlClient.getQuizData(token, this.props.quizIdentifier).then(result => {

            let questions = new Array<QuestionModel>();
            result.data.trainingEpisodeContent.questions.map((question: any) => {

                let answers = new Array<AnswerModel>();

                question.answers.map((answer: any) => {
                    answers.push(new AnswerModel(answer.number, answer.answer, answer.isCorrect, answer.userAnswer, answer.score));
                });

                questions.push(new QuestionModel(question.number, question.question, answers, 0, "", 0));
            });


            this.setState(state => {
                let quiz = new QuizModel(
                    result.data.trainingEpisodeContent.title,
                    result.data.trainingEpisodeContent.summary,
                    new DetailsModel("", "", "", "", false, false),
                    new Array<SectionModel>(),
                    questions,
                    outroContent,
                    result.data.trainingEpisodeContent.isComplete);
                const currentSection = state.currentSection;
                const currentQuestion = 0;
                const started = true;
                const completed = false;
                const currentOutro = state.currentOutro;

                return {
                    quiz,
                    currentSection,
                    currentQuestion,
                    currentOutro,
                    started,
                    completed
                };
            });
        });

    }

    onStart = () => {

        this.setState(state => {
            const quiz = state.quiz;
            const currentSection = 0;
            const currentQuestion = 0;
            const started = true;
            const completed = false;
            const currentOutro = state.currentOutro;

            return {
                quiz,
                currentSection,
                currentQuestion,
                currentOutro,
                started,
                completed
            };
        });

    };

    answerQuestion = (userAnswer: string, score: number, answerNumber: number, evt: any) => {

        this.setState(state => {
            const quiz = state.quiz;
            let currentSection = state.currentSection;
            let currentQuestion = state.currentQuestion;
            let currentOutro = state.currentOutro;
            let started = state.started;
            let completed = state.completed;

            quiz.questions[currentQuestion].userAnswer = userAnswer;
            quiz.questions[currentQuestion].userAnswerIndex = answerNumber;
            quiz.questions[currentQuestion].score = score;

            if (currentQuestion === quiz.questions.length - 1) {
                completed = true;
                this.submit();
                currentOutro++;
            }
            else {
                currentQuestion++;
            }

            return {
                quiz,
                currentSection,
                currentQuestion,
                currentOutro,
                started,
                completed
            };
        });

    };

    async submit() {

        let questions = new Array<TrainingEpisodeQuestionDataModel>();

        this.state.quiz.questions.map((question, index) => questions.push(new TrainingEpisodeQuestionDataModel(index + 1, question.userAnswerIndex, 1)));

        let submission = new TrainingEpisodeDataModel(this.props.quizIdentifier, questions);

        var token = await authentication.checkToken("");

        await gqlClient.postQuizResponse(token, submission).then(result => {
        });


    }

    render() {

        let title;
        let content;

        if (this.state.quiz.questions.length == 0) {
            content = <>
                <h1>Loading</h1>
                <hr className="separator" />
                <div className="summary">
                </div>
            </>
        }
        else if (this.state.currentQuestion == -1 && !this.state.started) {
            content = <>
                <div className="quizIntro">
                    <h1>{this.state.quiz.title}</h1>
                    <hr className="separator" />
                    <div className="summary">
                        {this.state.quiz.summary}
                    </div>
                    <div className="loginLink">
                        <a onClick={this.onStart}>Continue</a>
                    </div>
                </div>
            </>
        }
        else if (this.state.completed) {
            content = <>
                <div className="quizIntro">
                    <h1>{this.state.quiz.outroContent[this.state.currentOutro].title}</h1>
                    <hr className="separator" />
                    <div className="summary">
                        {this.state.quiz.outroContent[this.state.currentOutro].summary}
                    </div>
                    <div className="loginLink">
                        <a onClick={this.props.onComplete.bind(this)}>{this.state.quiz.outroContent[this.state.currentOutro].closeButtonText}</a>
                    </div>
                </div>
            </>

        }
        else {
            title = <>Question {this.state.currentQuestion + 1} of {this.state.quiz.questions.length}</>
            content = <>
                <div className="quizBackground">
                    <Question onAnswerQuestion={this.answerQuestion} text={this.state.quiz.questions[this.state.currentQuestion].text} answers={this.state.quiz.questions[this.state.currentQuestion].answers} />
                </div>
            </>
        }

        return (<>
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="quizDialog">
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {content}

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>);
    }
}

export default Quiz;