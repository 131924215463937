import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter, RouteProps } from "react-router-dom";
import { Config } from '../Config';
import AuthenticatedComponent from './AuthenticatedComponent';
import Authentication from '../Authentication'
import GraphQLClient from '../GraphQLClient'
import { UserDataModel, UserQuestionDataModel, ZoneStateModel, QuestionModel, AnswerModel, EmailExportModel } from '../Models'
import { ListGroup, Button } from 'react-bootstrap';
//@ts-ignore
import { CSVLink, CSVDownload } from "react-csv";

declare global {
    interface Window { hpVillageDate: Date; }
}

interface IProps {
    devMode: boolean;
}

interface IState {
    zoneStateData: Array<ZoneStateModel>;
    answers: Array<UserDataModel>;
    answersLoading: boolean;
    quizId: string;
    winners: Array<EmailExportModel>;
    nonWinners: Array<EmailExportModel>;
    incorrectAnswers: Array<EmailExportModel>;
}

interface IRouteParams {
}

const authentication = new Authentication();
const gqlClient = new GraphQLClient();

class Admin extends AuthenticatedComponent<IProps & RouteComponentProps<IRouteParams>, IState> {

    constructor(props: any) {
        super(props);

        authentication.roles().then(result => {
            if (result.filter((role: string) => role == "training_admin").length == 0)
            {
                this.props.history.push("/");
            }
        });

        let zoneStates = new Array<ZoneStateModel>();
        let questions = new Array<QuestionModel>();
        let userData = new Array<UserDataModel>();

        Config.zones.map((zone) => {

            zone.states.map((zoneState) => {
                zoneStates.push(new ZoneStateModel(zoneState.image, new Date(Date.parse(zoneState.start)), new Date(Date.parse(zoneState.finish)), zoneState.quizId));
            });

        });

        this.state = {
            zoneStateData: zoneStates,
            answers: userData,
            answersLoading: false,
            quizId: "",
            winners: new Array<EmailExportModel>(),
            nonWinners: new Array<EmailExportModel>(),
            incorrectAnswers: new Array<EmailExportModel>()
        }
    }

     componentDidMount = async() => {
       
    }

    chooseWinner = async() => {

        let maxWinners = 10;
        let maxEligible = this.state.answers.filter((answer) => { return answer.eligible == true}).length;
        
        if (maxEligible < maxWinners)
        {
            maxWinners = maxEligible;
        }

        let winners = Array<EmailExportModel>();
        let chosenIndexes = new Array<number>();
        const answers = [...this.state.answers];

        while (winners.length != maxWinners)
        {
            let newWinner = Math.floor(Math.random()*answers.length);

            if (chosenIndexes.filter((i) => {return i == newWinner}).length == 0 &&
                answers[newWinner].eligible)
            {
                answers[newWinner].winner = true;
                winners.push(new EmailExportModel(answers[newWinner].user));
                chosenIndexes.push(newWinner);
            }
        }

        let nonWinners = Array<EmailExportModel>();
        let incorrectAnswers = Array<EmailExportModel>();
        
        for (let i = 0; i < this.state.answers.length; i++)
        {
            if (chosenIndexes.filter((index) => { return index == i}).length == 0)
            {
                if (answers[i].eligible)
                    nonWinners.push(new EmailExportModel(answers[i].user));
                else
                    incorrectAnswers.push(new EmailExportModel(answers[i].user));
            }
        }

        this.setState((state) => {
            return {
                answers: answers,
                winners: winners,
                nonWinners: nonWinners,
                incorrectAnswers: incorrectAnswers
            };
        });

    }

    alertClicked = async(quizId: string) => {
        
        var token = await authentication.checkToken("");

        this.setState((state) => {
            return {
                quizId: quizId,
                answersLoading: true
            };
        });

        await gqlClient.getQuizData(token, quizId).then(async(result) => {

            let questions = new Array<QuestionModel>();
            result.data.trainingEpisodeContent.questions.map((question: any) => {

                let answers = new Array<AnswerModel>();

                question.answers.map((answer: any) => {
                    answers.push(new AnswerModel(answer.number, answer.answer, answer.isCorrect, answer.userAnswer, answer.score));
                });

                questions.push(new QuestionModel(question.number, question.question, answers, 0, "", 0));
            });

            await gqlClient.getAnswerData(token, quizId).then(result => {

                let userData = new Array<UserDataModel>();
    
                result.data.trainingEpisodeData.edges.map((record: any) => {
    
                    let node = record.node;
    
                    let questionData = new Array<UserQuestionDataModel>();
    
                    node.questionData.map((qData: any) => {
                        questionData.push(new UserQuestionDataModel(qData.questionNumber, (qData.answerNumber+1), qData.attempts));
                    });
    
                    userData.push(new UserDataModel(node.user, node.episodeScore, questionData, false, questions));
    
                });
    
    
                this.setState((state) => {
                    return {
                        answers: userData,
                        answersLoading: false
                    };
                });
            });

        });

      }

    render() {

        let quizDisplay: Array<any> = [];

        this.state.zoneStateData.map((zoneState) => {
            if (zoneState.closed || this.props.devMode)
                quizDisplay.push(<ListGroup.Item action onClick={this.alertClicked.bind(this, zoneState.quizId)}>{zoneState.quizId} - closes: {zoneState.finishDisplay}</ListGroup.Item>)
            else
                quizDisplay.push(<ListGroup.Item disabled onClick={this.alertClicked.bind(this, zoneState.quizId)}>{zoneState.quizId} - closes: {zoneState.finishDisplay}</ListGroup.Item>)
        });

        let answerDisplay: Array<any> = [];

        if (!this.state.answersLoading)
        {

            this.state.answers.map((answer) => {
                answerDisplay.push(<ListGroup.Item variant={(answer.winner ? 'success':(answer.eligible?'primary':''))}>{answer.user} - {answer.correctAnswerCount} of {answer.questionCount} correct.</ListGroup.Item>)
            });

            if (answerDisplay.length > 0 && this.state.winners.length == 0)
            {
                answerDisplay.push(<Button variant="success" onClick={this.chooseWinner.bind(this)}>Choose Winner</Button>)
            }

            if (this.state.winners.length > 0)
            {
                if (this.state.winners.length > 0)
                    answerDisplay.push(<CSVLink filename={`${this.state.quizId} Winners.csv`} data={this.state.winners}>Download Winners</CSVLink>)

                if (this.state.nonWinners.length > 0)
                    answerDisplay.push(<CSVLink filename={`${this.state.quizId} Non-Winners.csv`} data={this.state.nonWinners}>Download Non-Winners</CSVLink>)

                if (this.state.incorrectAnswers.length > 0)
                    answerDisplay.push(<CSVLink filename={`${this.state.quizId} Incorrect Answers.csv`} data={this.state.incorrectAnswers}>Download Incorrect Answers</CSVLink>)
            }
        }

        return (
            <>
                <div className="quizSelectionContainer">
                <ListGroup>
                    {quizDisplay}
                </ListGroup>
                </div>
                <div className="quizAnswerContainer">
                <ListGroup>
                    {answerDisplay}
                </ListGroup>
                </div>
            </>
        );
    }
}

export default withRouter(Admin);