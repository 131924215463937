export const Config = {
//    appUri: "https://imps-heroes-staging.azurewebsites.net",
    appUri: "https://hpheroes.channel-incentives.com",
    apiUri: "https://api.out-bound.co.uk",
//    appUri: "http://localhost:3000",
//    apiUri: "https://localhost:44316",
    birdsEye: [
        {
            id: 1,
            coords: "0,821,658,428,1312,812,1231,865,1226,903,977,1050,945,1030,893,1068,882,1086,875,1109,842,1098,773,1148,767,1175,672,1220",
            image: './full-map/state1.png',
            start: "2021-01-01T00:00:00.000Z"
        },
        {
            id: 2,
            coords: "685,437,907,305,909,245,977,210,1011,229,1108,185,1118,163,1233,99,1283,86,1285,34,1310,16,1347,0,1423,39,1419,91,1458,115,1498,88,1621,165,1623,210,1639,215,1679,219,1790,281,1797,308,1838,306,1879,321,1892,303,1933,297,1963,312,1958,342,1960,362,1971,396,1983,421,2021,443,1394,812,1360,783,1310,804",
            image: './full-map/state2.png',
            start: "2021-11-18T00:00:00.000Z"
        },
        {
            id: 3,
            coords: "676,1220,773,1172,782,1147,846,1102,882,1113,900,1071,945,1036,982,1053,1235,899,1238,867,1364,790,1385,813,1394,855,1494,917,1505,903,1596,950,1607,967,1654,960,1686,1005,1781,1057,1777,1079,1802,1091,1811,1080,1847,1096,1856,1123,2024,1220,1353,1611",
            image: './full-map/state3.png',
            start: "2021-12-09T00:00:00.000Z"
        },
        {
            id: 4,
            coords: "",
            image: './full-map/state4.png',
            start: "2022-01-19T23:59:59.999Z",
        }
    ],
    zones: [
        {
            id: "1",
            states: [
                {
                    image: './zones/1/state1.gif',
                    start: "2021-01-01T00:00:00.000Z",
                    finish: "2021-11-03T23:59:59.999Z",
                    devStart: "2021-01-01T00:00:00.000Z",
                    devFinish: "2021-11-03T23:59:59.999Z",
                    quizId: "zone1-quiz1",
                    coords: "579,326,603,310,616,317,594,282,594,258,601,236,619,224,639,218,665,221,681,235,688,254,688,274,670,309,684,307,719,356,711,360,712,370,767,400,770,418,766,456,772,464,742,478,700,462,701,445,682,438,670,443,669,457,656,451,609,428,613,409,571,389,579,372",
                    hints: [
                        {
                            coords: "523,642,522,600,553,585,553,626",
                            text:"Every year, 8 million metric tons of plastic enters our oceans, joining the estimated 150 million metric tons already circulating."
                        },
                        {
                            coords: "367,390,379,396,393,390,399,358,380,345,377,327,360,338,362,356",
                            text:"More than 85M bottles' worth of ocean-bound plastics have been used in HP products since 2016."
                        },
                        {
                            coords: "842,507,874,492,893,508,909,526,921,554,890,569,836,541",
                            text: "To date, HP’s Planet Partners recycling program has kept 916 million HP cartridges, 127 million apparel hangers and 5 billion post-consumer plastic bottles out of landfills."
                        }
                    ]
                },
                {
                    image: './zones/1/state2.gif',
                    start: "2021-11-04T00:00:00.000Z",
                    finish: "2021-11-10T23:59:59.999Z",
                    devStart: "2021-11-04T00:00:00.000Z",
                    devFinish: "2021-11-10T23:59:59.999Z",
                    quizId: "zone1-quiz2",
                    coords: "579,326,603,310,616,317,594,282,594,258,601,236,619,224,639,218,665,221,681,235,688,254,688,274,670,309,684,307,719,356,711,360,712,370,767,400,770,418,766,456,772,464,742,478,700,462,701,445,682,438,670,443,669,457,656,451,609,428,613,409,571,389,579,372",
                    hints: [
                        {
                            coords: "523,642,522,600,553,585,553,626",
                            text:"Unsecured printers can leave businesses exposed to attacks. HP Wolf Pro Security uses continuous hardware-powered protection to help keep endpoints secure and users productive."
                        },
                        {
                            coords: "367,390,379,396,393,390,399,358,380,345,377,327,360,338,362,356",
                            text:"HP OfficeJet Pro and HP LaserJet Pro printers have embedded security features that safeguard against cyberattacks."
                        },
                        {
                            coords: "842,507,874,492,893,508,909,526,921,554,890,569,836,541",
                            text: "By building protection right into the hardware, firmware and OS, HP make sure the printer stays secure and resilient, reducing dependency on good end-user behaviour."
                        }
                    ]
                },
                {
                    image: './zones/1/state3.gif',
                    start: "2021-11-11T00:00:00.000Z",
                    finish: "2030-11-17T23:59:59.999Z",
                    devStart: "2021-11-11T00:00:00.000Z",
                    devFinish: "2030-11-17T23:59:59.999Z",
                    quizId: "zone1-quiz3",
                    coords: "579,326,603,310,616,317,594,282,594,258,601,236,619,224,639,218,665,221,681,235,688,254,688,274,670,309,684,307,719,356,711,360,712,370,767,400,770,418,766,456,772,464,742,478,700,462,701,445,682,438,670,443,669,457,656,451,609,428,613,409,571,389,579,372",
                    hints: [
                        {
                            coords: "523,642,522,600,553,585,553,626",
                            text:"HP LaserJet Pro printers are built for 1-15 people, printing up to 6,000 pages per month."
                        },
                        {
                            coords: "367,390,379,396,393,390,399,358,380,345,377,327,360,338,362,356",
                            text:"LaserJet Pro printers are the world’s smallest lasers in their class that fit small business’s space and budget."
                        },
                        {
                            coords: "842,507,874,492,893,508,909,526,921,554,890,569,836,541",
                            text: "The HP LaserJet Pro M15w and MFP M28w allow employees to print, scan, and copy. Producing professional quality results and allowing employees to print and scan from their smartphone."
                        }
                    ]
                }
            ],
        },
        {
            id: "2",
            states: [
                {
                    image: './zones/2/state1.gif',
                    start: "2021-11-18T00:00:00.000Z",
                    finish: "2021-11-24T23:59:59.999Z",
                    devStart: "2021-01-01T00:00:00.000Z",
                    devFinish: "2021-11-24T23:59:59.999Z",
                    quizId: "zone2-quiz1",
                    coords: "446,170,511,131,508,118,514,97,534,76,555,69,576,74,587,81,597,91,604,106,606,125,604,136,671,170,672,217,700,236,507,275,422,230,444,216",
                    hints: [
                        {
                            coords: "592,763,588,733,582,714,603,703,609,721,622,733,619,764,605,771",
                            text:"HP keep products in use and reduce waste by designing products for long life, offering service-based solutions that improve customer value and decrease environmental impacts, as well as recapturing products and materials "
                        },
                        {
                            coords: "1064,530,1091,548,1092,579,1042,609,1012,593,1018,572,1053,533",
                            text:"93% of HP printers are ENERGY STAR® certified. ENERGY STAR is the simple choice for energy efficiency, making it easy for businesses to purchase products that save them money and protect the environment."
                        },
                        {
                            coords: "979,358,1007,339,1007,383,980,401",
                            text:"1.38 MILLION TONNES of supply chain CO2e emissions avoided through improvements to product energy and efficiency."
                        }
                    ]
                },
                {
                    image: './zones/2/state2.gif',
                    start: "2021-11-25T00:00:00.000Z",
                    finish: "2021-12-01T23:59:59.999Z",
                    devStart: "2021-11-25T00:00:00.000Z",
                    devFinish: "2021-12-01T23:59:59.999Z",
                    quizId: "zone2-quiz2",
                    coords: "446,170,511,131,508,118,514,97,534,76,555,69,576,74,587,81,597,91,604,106,606,125,604,136,671,170,672,217,700,236,507,275,422,230,444,216",
                    hints: [
                        {
                            coords: "592,763,588,733,582,714,603,703,609,721,622,733,619,764,605,771",
                            text:"With HP Security Manager, a single IT person can manage an entire fleet of printers, including setting security policies, helping to ensure compliance, and protecting workflows."
                        },
                        {
                            coords: "1064,530,1091,548,1092,579,1042,609,1012,593,1018,572,1053,533",
                            text:"HP Wolf Security allows businesses to print sensitive documents to shared printers and only lets authorized users release them with a QR code, keeping print jobs private and reducing waste."
                        },
                        {
                            coords: "979,358,1007,339,1007,383,980,401",
                            text:"Write Protected Memory prevents intruders from introducing malicious code while the printer is running."
                        }
                    ]
                },
                {
                    image: './zones/2/state3.gif',
                    start: "2021-12-02T00:00:00.000Z",
                    finish: "2030-12-08T23:59:59.999Z",
                    devStart: "2021-12-02T00:00:00.000Z",
                    devFinish: "2030-12-08T23:59:59.999Z",
                    quizId: "zone2-quiz3",
                    coords: "446,170,511,131,508,118,514,97,534,76,555,69,576,74,587,81,597,91,604,106,606,125,604,136,671,170,672,217,700,236,507,275,422,230,444,216",
                    hints: [
                        {
                            coords: "592,763,588,733,582,714,603,703,609,721,622,733,619,764,605,771",
                            text:"HP LaserJet Enterprise printers are built for 10-30 people printing up to 30,000 pages per month."
                        },
                        {
                            coords: "1064,530,1091,548,1092,579,1042,609,1012,593,1018,572,1053,533",
                            text:"HP PageWide Business Printers have the best-in-class total cost of ownership and fastest speeds, plus the world’s most secure printing and best-in-class energy efficiency."
                        },
                        {
                            coords: "979,358,1007,339,1007,383,980,401",
                            text:"The lowest total cost of ownership refers to the savings made on ongoing print costs."
                        }
                    ]
                }
            ]
        },
        {
            id: "3",
            states: [
                {
                    image: './zones/3/state1.gif',
                    start: "2021-12-09T00:00:00.000Z",
                    finish: "2021-12-15T23:59:59.999Z",
                    devStart: "2021-01-01T00:00:00.000Z",
                    devFinish: "2021-12-15T23:59:59.999Z",
                    quizId: "zone3-quiz1",
                    coords: "660,502,622,481,620,466,593,450,592,424,600,422,555,391,557,363,540,352,539,332,605,285,556,256,557,233,593,210,593,202,606,195,593,187,594,171,601,164,601,146,644,118,662,127,652,96,656,72,675,51,700,44,729,55,742,72,748,96,739,117,781,145,779,177,796,187,796,209,783,214,785,229,794,234,792,253,790,278,794,297,783,307,788,320,796,326,797,342,789,350,794,363,795,387,789,407,796,411,796,432,785,442,786,454,796,461,794,478,733,516,714,518",
                    hints: [
                        {
                            coords: "899,361,899,314,925,300,927,343",
                            text:"HP have achieved zero deforestation associated with HP brand paper and paper-based product packaging. However, HP are still driving towards a forest positive future."
                        },
                        {
                            coords: "859,457,877,443,912,463,917,479,917,497,863,527,829,508,844,473",
                            text:"In 2019, HP pledged $11 million to support WWF’s efforts to restore part of Brazil’s threatened Atlantic Forest and improve the management of state-owned and private forest plantations in China. This will ultimately restore, protect, and transition 200,000 acres of forests to responsible management."
                        },
                        {
                            coords: "447,402,435,398,428,364,422,340,444,331,447,352,464,362,464,391",
                            text:"A tree was planted for each HP employee among other initiatives in partnership with the Arbour Day Foundation and the World Economic Forum."
                        }
                    ]
                },
                {
                    image: './zones/3/state2.gif',
                    start: "2021-12-16T00:00:00.000Z",
                    finish: "2022-01-12T23:59:59.999Z",
                    devStart: "2021-12-16T00:00:00.000Z",
                    devFinish: "2022-01-12T23:59:59.999Z",
                    quizId: "zone3-quiz2",
                    coords: "660,502,622,481,620,466,593,450,592,424,600,422,555,391,557,363,540,352,539,332,605,285,556,256,557,233,593,210,593,202,606,195,593,187,594,171,601,164,601,146,644,118,662,127,652,96,656,72,675,51,700,44,729,55,742,72,748,96,739,117,781,145,779,177,796,187,796,209,783,214,785,229,794,234,792,253,790,278,794,297,783,307,788,320,796,326,797,342,789,350,794,363,795,387,789,407,796,411,796,432,785,442,786,454,796,461,794,478,733,516,714,518",
                    hints: [
                        {
                            coords: "899,361,899,314,925,300,927,343",
                            text:"Automatic firmware updates are on by default, which protects businesses against the latest threats by always maintaining the latest version of the printer firmware."
                        },
                        {
                            coords: "859,457,877,443,912,463,917,479,917,497,863,527,829,508,844,473",
                            text:"Secure Boot with Firmware Validation validates the firmware as genuine HP code at device start-up. If an anomaly is detected, the device reboots to a secure recovery state."
                        },
                        {
                            coords: "447,402,435,398,428,364,422,340,444,331,447,352,464,362,464,391",
                            text:"At every step of the design, supply chain, and production process for Original HP office cartridges, security is built-in with tamper-resistant chips, firmware and packaging."
                        }
                    ]

                },
                {
                    image: './zones/3/state3.gif',
                    start: "2022-01-13T00:00:00.000Z",
                    finish: "2030-01-21T23:59:59.999Z",
                    devStart: "2021-11-13T00:00:00.000Z",
                    devFinish: "2030-01-21T23:59:59.999Z",
                    quizId: "zone3-quiz3",
                    coords: "660,502,622,481,620,466,593,450,592,424,600,422,555,391,557,363,540,352,539,332,605,285,556,256,557,233,593,210,593,202,606,195,593,187,594,171,601,164,601,146,644,118,662,127,652,96,656,72,675,51,700,44,729,55,742,72,748,96,739,117,781,145,779,177,796,187,796,209,783,214,785,229,794,234,792,253,790,278,794,297,783,307,788,320,796,326,797,342,789,350,794,363,795,387,789,407,796,411,796,432,785,442,786,454,796,461,794,478,733,516,714,518",
                    hints: [
                        {
                            coords: "899,361,899,314,925,300,927,343",
                            text:"HP PageWide Pro MFP 477dw is the fastest in its class - prints up to 55 ppm with single-pass, two-sided scanning and the fastest first-page printing in its class."
                        },
                        {
                            coords: "859,457,877,443,912,463,917,479,917,497,863,527,829,508,844,473",
                            text:"HP has been awarded the BLI Most Reliable Business Printer & MFP Brand from 2018-2021."
                        },
                        {
                            coords: "447,402,435,398,428,364,422,340,444,331,447,352,464,362,464,391",
                            text:"HP+ is the smarter printing system that thinks ahead so your printer is ready when you are. More secure, more productive, and more sustainable than ever. Customers signing up to HP+ get 6 months of Instant Ink and one extra "
                        }
                    ]

                }
            ],
        }
    ]
};