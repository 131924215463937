import { Component } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Config } from '../Config';

import Authentication from '../Authentication'

interface IProps {
}

interface IState {
}

interface IRouteParams {
    
}

const authentication = new Authentication();

class Login extends Component<IProps & RouteComponentProps<IRouteParams>, IState> {

    constructor(props: any) {
        super(props);
    }

    async componentDidMount(){
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const code = params.get('code');
        
        if (null === code)
            window.location.href = `${Config.apiUri}/account/login/?returnurl=%2Fconnect%2Fauthorize%3Fclient_id%3Dhpvillage%26redirect_uri%3D${encodeURI(Config.appUri)}%2Flogin%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%2520roles%2520email`;
        else
        {
            var token = await authentication.checkToken(code);
            window.location.href = '/birdseye';
        }
    }

    render() {
        return (
            <>
            </>
        );
    }
}

export default withRouter(Login);