import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

import logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface IProps {
    show: boolean,
    onContinue: any;
}

interface IState {
}

class TouristInformation extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);
    }

    async componentDidMount() {
    }

    render() {

        return (<>
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="informationCentreDialog">
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <img src={logo} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="ic-header"><FontAwesomeIcon icon={faInfoCircle} /></div>
                    <div className="ic-header">HP HEROES HELP HUB​</div>

                    <div className="ic-content">
                        <p>Discover the new HP Village and all it has to offer.​</p>
                        <p>There are 3 zones in the village which will be opened up in the coming months, watch out for email notifications to let you know when each zone is open.​</p>
                        <p>There are a number of quizzes in each zone, you will find some helpful prompts scattered around the area. Look out for the animated items to find these.​</p>
                        <p>For each quiz, answer all questions correctly first time within a week of it being released and you will go into the draw for one of 10 instant wins. ​</p>
                        <p>So don’t hang around or others may get there first!​</p>
                    </div>

                    <div className="loginLink">
                        <a onClick={this.props.onContinue.bind(this)}>Continue</a>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>);
    }
}

export default TouristInformation;