import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

import logo from '../images/logo.png';
import hintLogo from '../images/imps-hero-hint.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface IProps {
    hintText: string,
    show: boolean,
    onContinue: any;
}

interface IState {
}

class Hint extends Component<IProps, IState> {

	constructor(props: any) {
		super(props);
	}

    async componentDidMount() {
    }

	render() {

		return (<>
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="hintDialog">
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <img src={hintLogo} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div className="ic-content hint-content">
                    {this.props.hintText}
                </div>
                    
                <div className="loginLink">
                    <a onClick={this.props.onContinue.bind(this, '')}>Continue</a>
                </div>

                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal>
		</>);
	}
}

export default Hint;