import { RouteComponentProps, withRouter, RouteProps } from "react-router-dom";
import Quiz from './Quiz';
import { Config } from '../Config'
import AuthenticatedComponent from './AuthenticatedComponent';
import { HintModel, InteractionDataModel, InteractionEventType } from '../Models';
import Hint from './Hint';
import landscape from '../images/landscape.png';
import Authentication from '../Authentication'
import GraphQLClient from '../GraphQLClient'

//@ts-ignore
import imageMapResize from 'image-map-resizer'

declare var require: any

const images = require.context('../images', true);

interface IProps {
    anchorDate: Date;
    devMode: boolean;
}

interface IState {
    zone: any,
    zoneImage: string,
    zoneState: any,
    displayQuiz: boolean,
    displayHint: boolean,
    hintText: string,
    quizIdentifier: string,
    isComplete: boolean,
    isLoading: boolean
}

interface IRouteParams {
    zoneId: string
}

const authentication = new Authentication();
const gqlClient = new GraphQLClient();

class Zone extends AuthenticatedComponent<IProps & RouteComponentProps<IRouteParams>, IState> {

    constructor(props: any) {
        super(props);

        let zoneId = this.props.match.params.zoneId;
        let zone = Config.zones.filter((z) => { return z.id == zoneId });
        let state = zone[0].states.filter((z) => { return new Date(Date.parse(z.start)) <= this.props.anchorDate && new Date(Date.parse(z.finish)) >= this.props.anchorDate });

        if (this.props.devMode)
            state = zone[0].states.filter((z) => { return new Date(Date.parse(z.devStart)) <= this.props.anchorDate && new Date(Date.parse(z.devFinish)) >= this.props.anchorDate });

        let zoneImage = "";
        let quizId = "";
        let zoneState = null;

        if (state.length > 0) {
            zoneState = state[0];
            zoneImage = state[0].image;
            quizId = state[0].quizId;
        }

        this.state = {
            zone: zone[0],
            zoneImage: zoneImage,
            zoneState: zoneState,
            displayQuiz: false,
            displayHint: false,
            hintText: "",
            quizIdentifier: quizId,
            isComplete: false,
            isLoading: true
        };
    }

    componentDidMount = async () => {
        if (this.state.zoneState != null) {

            imageMapResize();


            var token = await authentication.checkToken("");

            let interactionData: InteractionDataModel = new InteractionDataModel(InteractionEventType.WebPageView, window.location.pathname, "", new Date(), "", "", 0, new Array<string>());

            await gqlClient.createInteraction(token, interactionData);

            await gqlClient.getQuizData(token, this.state.quizIdentifier).then(result => {

                this.setState(state => {

                    let zoneImage = this.state.zoneImage;
                    let isComplete = result.data.trainingEpisodeContent.isComplete;

                    if (isComplete)
                        zoneImage = `${zoneImage.replace(".gif", "")}-complete.gif`;

                    return {
                        zoneImage: zoneImage,
                        isComplete: isComplete,
                        isLoading: false
                    }

                });
            });
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.anchorDate !== this.props.anchorDate ||
            prevState.isComplete !== this.state.isComplete) {
            this.updateAndNotify();
        }

        if (prevState.zoneImage != this.state.zoneImage) {
            imageMapResize();
        }
    }

    updateAndNotify = () => {

        let zoneId = this.props.match.params.zoneId;
        let zone = Config.zones.filter((z) => { return z.id == zoneId });
        let zoneStateArr = zone[0].states.filter((z) => { return new Date(Date.parse(z.start)) <= this.props.anchorDate && new Date(Date.parse(z.finish)) >= this.props.anchorDate });

        if (this.props.devMode)
            zoneStateArr = zone[0].states.filter((z) => { return new Date(Date.parse(z.devStart)) <= this.props.anchorDate && new Date(Date.parse(z.devFinish)) >= this.props.anchorDate });

        let zoneImage = "";
        let quizId = "";
        let zoneState: any = null;

        if (zoneStateArr.length > 0) {
            zoneState = zoneStateArr[0];
            zoneImage = zoneStateArr[0].image;

            if (this.state.isComplete)
                zoneImage = `${zoneImage.replace(".gif", "")}-complete.gif`;

            quizId = zoneStateArr[0].quizId;
        }

        if (zoneState != null) {
            this.setState(state => {
                return {
                    zoneImage: zoneImage,
                    zoneState: zoneState,
                    quizIdentifier: zoneState.quizId,
                }
            });

        }
    }

    toggleQuiz = async () => {

        if (this.state.isLoading || (!this.state.displayQuiz && this.state.isComplete))
            return;

        const displayQuiz = !this.state.displayQuiz;

        if (displayQuiz) {
            var token = await authentication.checkToken("");
            let interactionData: InteractionDataModel = new InteractionDataModel(InteractionEventType.TrainingEpisodeView, this.state.quizIdentifier, "", new Date(), "", "", 0, new Array<string>());
            await gqlClient.createInteraction(token, interactionData);
        }

        this.setState(state => {
            return {
                displayQuiz
            }

        });


    };

    markComplete = () => {

        this.toggleQuiz();

        this.setState(state => {

            return {
                isComplete: true
            }

        });


    };

    toggleHint = (hintText: string) => {

        this.setState(state => {

            const displayHint = !state.displayHint;

            return {
                hintText,
                displayHint
            }

        });

    };

    render() {

        let hints = new Array<HintModel>();
        let image: any = <></>;

        if (this.state.zoneState != null) {

            image = <><img className="imageMap" src={images(`${this.state.zoneImage}`).default} useMap="#image-map" /></>;
            for (let i = 0; i < this.state.zoneState.hints.length; i++) {
                hints.push(new HintModel(this.state.zoneState.hints[i].coords, this.state.zoneState.hints[i].text));
            }
        }

        return (
            <>
                <div className="app-content-portrait">
                    <map name="image-map">
                        <area target="" alt="" title="" onClick={this.toggleQuiz.bind(this)} coords={this.state.zone.states[0].coords} shape="poly" />
                        {
                            hints.map((hint, key) => {
                                return (<area key={key} target="" alt="" title="" onClick={this.toggleHint.bind(this, hint.text)} coords={hint.coords} shape="poly" />)
                            })
                        }
                    </map>
                    {image}
                    <Quiz show={this.state.displayQuiz} onComplete={this.markComplete} quizIdentifier={this.state.quizIdentifier} />
                    <Hint show={this.state.displayHint} hintText={this.state.hintText} onContinue={this.toggleHint} />
                </div>
                <div className="app-content-landscape">
                    <img src={landscape} />
                    <h3>Rotate Device</h3>
                </div>
            </>
        );

    }
}

export default withRouter(Zone);

//