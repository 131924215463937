import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter, RouteProps } from "react-router-dom";
import fullMap from '../images/full-map.png';
import { Config } from '../Config';
import { BirdseyeZoneModel, InteractionDataModel, InteractionEventType } from '../Models';
import AuthenticatedComponent from './AuthenticatedComponent';
import landscape from '../images/landscape.png';
import GraphQLClient from '../GraphQLClient'
import Authentication from '../Authentication'
//@ts-ignore
import imageMapResize from 'image-map-resizer';

declare global {
    interface Window { hpVillageDate: Date; }
}

declare var require: any

const images = require.context('../images', true);

interface IProps {
    anchorDate: Date;
}

interface IState {
    mapImage: string,
    liveZones: Array<BirdseyeZoneModel>
}

interface IRouteParams {
}

const authentication = new Authentication();
const gqlClient = new GraphQLClient();

class BirdsEye extends AuthenticatedComponent<IProps & RouteComponentProps<IRouteParams>, IState> {

    constructor(props: any) {
        super(props);

        let liveZonesConfig = Config.birdsEye.filter((z) => { return new Date(Date.parse(z.start)) <= this.props.anchorDate });

        let liveZones = new Array<BirdseyeZoneModel>();

        for (let i = 0; i < liveZonesConfig.length; i++) {
            liveZones.push(new BirdseyeZoneModel(liveZonesConfig[i].id, liveZonesConfig[i].coords));
        }

        this.state = {
            mapImage: liveZonesConfig[liveZonesConfig.length - 1].image,
            liveZones: liveZones
        };
    }

    componentDidMount = async() => {
        var token = await authentication.checkToken("");

        let interactionData: InteractionDataModel = new InteractionDataModel(InteractionEventType.WebPageView, window.location.pathname, "", new Date(), "", "", 0, new Array<string>());

        await gqlClient.createInteraction(token, interactionData);

        imageMapResize();
    }

    loadZone = (zoneId: number) => {
        this.props.history.push(`/zone/${zoneId}`);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.anchorDate !== this.props.anchorDate) {
            this.updateAndNotify();
        }
    }

    updateAndNotify = () => {
        let liveZonesConfig = Config.birdsEye.filter((z) => { return new Date(Date.parse(z.start)) <= this.props.anchorDate });

        let liveZones = new Array<BirdseyeZoneModel>();

        for (let i = 0; i < liveZonesConfig.length; i++) {
            liveZones.push(new BirdseyeZoneModel(liveZonesConfig[i].id, liveZonesConfig[i].coords));
        }

        this.setState(state => {
            return {
                mapImage: liveZonesConfig[liveZonesConfig.length - 1].image,
                liveZones: liveZones
            }

        });

        //imageMapResize();
    }

    render() {

        let imageMap = <></>;

        if (this.state.liveZones.length < 4)
        {
            imageMap = <><map name="image-map">
            {
                this.state.liveZones.map((zone, key) => {
                    if ("" !== zone.coords)
                        return (<area key={key} target="" alt="" title="" onClick={this.loadZone.bind(this, zone.id)} coords={zone.coords} shape="poly" />)
                })
            }
        </map></>;
        }

        return (
            <>
                <div className="app-content-portrait">
                    {imageMap}

                    <img src={images(`${this.state.mapImage}`).default} className="fullMap imageMap" useMap="#image-map" />
                </div>
                <div className="app-content-landscape">
                    <img src={landscape} />
                    <h3>Rotate Device</h3>
                </div>
            </>
        );
    }
}

export default withRouter(BirdsEye);