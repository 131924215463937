import React from 'react';
import { AnswerModel } from '../Models';
import Answer from './Answer';


interface IProps {
    onAnswerQuestion: any;
    text: string;
    answers: Array<AnswerModel>;
 }

export default class Question extends React.PureComponent<IProps> {

    constructor(props: IProps) {
        super(props);
    }

    render() {

        return (
            <div>
                <div className="questionText">{ this.props.text }</div>
                <div className="answersContainer">
                {
                    this.props.answers.map((answer, index) => {
                        return (<Answer key={answer.key.toString()} onAnswerQuestion={this.props.onAnswerQuestion} text={answer.text} score={answer.score} answerNumber={index} />) 
                    })
                }
                </div>
            </div>
        );
    }
}
