import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter, RouteProps, Link } from "react-router-dom";
import logo from '../images/logo.png';
import Authentication from '../Authentication'

interface IProps {
}

interface IState {
}

interface IRouteParams {
}

const authentication = new Authentication();

class Home extends Component<IProps & RouteComponentProps<IRouteParams>, IState> {

    constructor(props: any) {
        super(props);
    }

    logIn = () => {
        if (authentication.isLoggedIn())
            this.props.history.push("/birdseye");
        else
            this.props.history.push("/login");
    };

    render() {
        return (
            <>
                <div>
                    <img src={logo} />
                </div>
                <div className="loginLink">
                    <a onClick={this.logIn.bind(this)}>Login/Register</a>
                </div>
            </>
        );
    }
}

export default withRouter(Home);