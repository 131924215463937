import { Component } from 'react';
import Authentication from '../Authentication'

interface IProps {
}

interface IState {
}

interface IRouteParams {
}

const authentication = new Authentication();

class AuthenticatedComponent<IProps, IState> extends Component<IProps, IState> {

	constructor(props: any) {
		super(props);

        var token = authentication.checkToken("").then(result => {
            if (result == null)
                window.location.href = "/login";
        });
	}
}

export default AuthenticatedComponent;