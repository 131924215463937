import React from 'react';

interface IProps {
    onAnswerQuestion: any;
    text: string;
    score: number;
    answerNumber: number;
 }

interface IAnswerState {
    text: string;
    score: number;
}

export default class Answer extends React.PureComponent<IProps, IAnswerState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {

        return (
            <div className="questionAnswer" onClick={this.props.onAnswerQuestion.bind(this, this.props.text, this.props.score, this.props.answerNumber)}>
                { this.props.text }                
            </div>
        );
    }
}
