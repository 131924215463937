import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.css';

import Header from './components/Header';
import Home from './components/Home';
import Login from './components/Login';
import Logout from './components/Logout';
import Birdseye from './components/Birdseye';
import Zone from './components/Zone';
//import ZoneV2 from './components/ZoneV2';
import Admin from './components/Admin';
import Authentication from './Authentication';
import GraphQLClient from './GraphQLClient';

import { InteractionDataModel, InteractionEventType } from './Models';

import landscape from './images/landscape.png';
import { unchangedTextChangeRange } from 'typescript';

const gqlClient = new GraphQLClient();
const authentication = new Authentication();

function App() {

	/*useEffect(() => {
		authentication.checkToken("").then((result) => {
			if (null !== result) {
				let interactionData: InteractionDataModel = new InteractionDataModel(InteractionEventType.AppOpen, window.location.pathname, "", new Date(), "", "", 0, new Array<string>());
        		gqlClient.createInteraction(result, interactionData);
			}
		});
	  }, []);*/

	const [anchorDate, setAnchorDate] = useState(new Date());
	const [devModeClicks, setDevModeClicks] = useState(0);
	const [devMode, setDevMode] = useState(false);

	function incrementDevModeClicks() {
 		setDevModeClicks(devModeClicks+1);

		 if (devModeClicks > 7)
		 	setDevMode(true);
	}

	function incrementDate() {

		let newDate = new Date(anchorDate.getFullYear(), anchorDate.getMonth(), anchorDate.getDate());
		newDate.setDate(newDate.getDate() + 1);
		setAnchorDate(newDate);
		
	}

	function decrementDate() {

		let newDate = new Date(anchorDate.getFullYear(), anchorDate.getMonth(), anchorDate.getDate());
		newDate.setDate(newDate.getDate() - 1);
		setAnchorDate(newDate);
		
	}

	return (
		<>
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<header>
							<Header anchorDate={anchorDate} incrementDate={incrementDate} decrementDate={decrementDate} incrementDevModeClicks={incrementDevModeClicks} devMode={devMode} />
						</header>
						<main>
							<div className="App-content">
								<Home />
							</div>
						</main>
					</Route>
					<Route exact path="/login">
						<header>
						<Header anchorDate={anchorDate} incrementDate={incrementDate} decrementDate={decrementDate} incrementDevModeClicks={incrementDevModeClicks} devMode={devMode} />
						</header>
						<main>
							<div className="App-content">
							<Login />
							</div>
						</main>
					</Route>
					<Route exact path="/logout">
						<header>
						<Header anchorDate={anchorDate} incrementDate={incrementDate} decrementDate={decrementDate} incrementDevModeClicks={incrementDevModeClicks} devMode={devMode} />
						</header>
						<main>
							<div className="App-content">
							<Logout />
							</div>
						</main>						
					</Route>
					<Route exact path="/birdseye">
						<header>
						<Header anchorDate={anchorDate} incrementDate={incrementDate} decrementDate={decrementDate} incrementDevModeClicks={incrementDevModeClicks} devMode={devMode} />
						</header>
						<main>
							<div className="App-content">
							<Birdseye anchorDate={anchorDate} />
							</div>
						</main>
					</Route>
					<Route path="/zone/:zoneId">
						<header>
						<Header anchorDate={anchorDate} incrementDate={incrementDate} decrementDate={decrementDate} incrementDevModeClicks={incrementDevModeClicks} devMode={devMode} />
						</header>
						<main>
							<div className="App-content">
							<Zone anchorDate={anchorDate} devMode={devMode} />
							</div>
						</main>
					</Route>
					<Route path="/admin">
						<header>
						<Header anchorDate={anchorDate} incrementDate={incrementDate} decrementDate={decrementDate} incrementDevModeClicks={incrementDevModeClicks} devMode={devMode} />
						</header>
						<main>
							<Admin devMode={devMode} />
						</main>
					</Route>
				</Switch>
			</BrowserRouter>

		</>
	);
}

export default App;
